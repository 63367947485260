<template>
  <div>
    <Card :parent_id="parent_id" style="margin-bottom: 31px">
      <p class="ti">我的认证</p>
    </Card>
    <div class="auth-boxs" v-if="iSRv">
      <div class="box1">
        <div class="box1-1">
          <img :src="shimin" alt="" />
          <div class="box1-right">
            <p>实名认证</p>
            <p>提高诚信级别，认识更多诚信用户</p>
            <p
              class="renzheng"
              v-if="iSrealAuthStatus == 0"
              @click="iSRv = false"
            >
              立即认证
            </p>
            <p class="Yrenzheng" v-if="iSrealAuthStatus == 2">已认证</p>
          </div>
        </div>
        <div class="box1-2">
          <img :src="renlian" alt="" />
          <div class="box1-right">
            <p>人脸认证</p>
            <p>做一个真实的人，提高被TA搜索的几率</p>
            <p
              class="renzheng"
              v-if="iSfaceAuthStatus == 0"
              @click="iSRv = false"
            >
              立即认证
            </p>
            <p class="Yrenzheng" v-if="iSfaceAuthStatus == 2">已认证</p>
          </div>
        </div>
      </div>
      <div class="box2">
        <img :src="xueli" alt="" />
        <div class="box1-right">
          <p>学历认证</p>
          <p>建立个人档案，提高个人真实度</p>
          <p class="renzheng" @click="iSApp = true" v-if="iSeduAuthStatus == 0">
            立即认证
          </p>
          <p class="Yrenzheng" v-if="iSeduAuthStatus == 2">已认证</p>
        </div>
      </div>
    </div>

    <div class="auth-rz" v-if="!iSRv">
      <img :src="rz" alt="" />

      <div class="sr srL">
        <p>真实姓名</p>
        <el-input
          v-model="name"
          maxlength="10"
          placeholder="请输入真实姓名"
        ></el-input>

        <div v-if="nameT" class="srL-name">姓名输入有误</div>
      </div>

      <div class="sr">
        <p>身份证号</p>
        <el-input
          v-model="sfzh"
          maxlength="18"
          placeholder="请输入身份证号码"
        ></el-input>
        <div v-if="sfzhT" class="srL-sf">身份证号输入有误</div>
      </div>

      <div class="auth-btn" @click="authS">前往认证</div>

      <div class="auth-title">
        1、也在网将严格保护会员隐私，请放心填写
        <br />
        2、如认证遇到困难，可咨询客服4008-234-521
        <br />
        3、如因电脑无摄像头等原因无法认证，可扫描下方二维码 下载APP认证
      </div>
      <div class="auth-img">
        <img :src="ewm" alt="" />
      </div>
    </div>

    <!-- 弹框 -->
    <div class="sqfw" v-if="iSApp">
      <div class="card">
        <div class="title">
          <p>下载APP认证</p>
          <!-- <img src="../../../assets/images/tubiao/close.d79c63e.gif" alt="" /> -->
          <i class="el-icon-close" @click="iSApp = false"></i>
        </div>
        <div class="qr">
          <img :src="ewm" alt="" />
          <p>扫描二维码 下载APP进行验证</p>
          <div class="btn" @click="iSApp = false">确认</div>
        </div>
      </div>
    </div>
    <!-- 弹框001 -->
    <div class="sqfwL" v-if="iSsm">
      <div class="card">
        <div class="title">
          <p>人脸认证操作指导</p>
          <!-- <img src="../../../assets/images/tubiao/close.d79c63e.gif" alt="" /> -->
          <i class="el-icon-close" @click="iSsm = false"></i>
        </div>
        <div class="qr">
          <p class="qr-ti">请在光线明亮的环境下，根据认证页面的提示进行操作</p>
          <div class="qr-img">
            <div>
              <img src="../../../assets/images/banner/wei-D.png" alt="" />
              <p>光线过亮</p>
            </div>
            <div>
              <img src="../../../assets/images/banner/wei-A.png" alt="" />
              <p>光线过暗</p>
            </div>
            <div>
              <img src="../../../assets/images/banner/wei-B.png" alt="" />
              <p>动作幅度过大</p>
            </div>
            <div>
              <img src="../../../assets/images/banner/wei-C.png" alt="" />
              <p>人脸在检测框内</p>
            </div>
          </div>
          <!-- -->
          <div class="btn" @click="jump">开始人脸认证</div>
          <p class="qr-title">
            若免费机会用完，身份证信息和人脸成功采集后，进行对比需扣除1个也在币
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card";
import { infocur } from "@/api/login/login.js";
import { authSb, iSidcard, authBack } from "@/api/my/my.js"; //
import local from "@/api/common/local.js";
// import axios from "axios";
import Qs from "qs";
import { subTk } from "@/api/member/member.js"; //Action

export default {
  data() {
    return {
      parent_id: "", //组件
      iSApp: false,
      shimin: require("@/assets/images/tubiao/renzheng.png"),
      renlian: require("@/assets/images/tubiao/renlian.png"),
      xueli: require("@/assets/images/tubiao/xueli.png"),
      iSrealAuthStatus: "",
      iSfaceAuthStatus: "",
      iSeduAuthStatus: "",
      iSRv: true,
      iSsm: false,
      rz: require("@/assets/images/banner/renlianSb.png"),
      ewm: require("@/assets/images/tubiao/ewm.png"),
      name: "",
      sfzh: "",
      url: "",
      sfzhT: false,
      nameT: false,
      userId: "",
      time: null,
    };
  },
  components: {
    Card,
  },
  created() {
    this.info();
    this.tkL();
    const _that = this;
    // local.set("iSza", 1);
    // if()
    // console.log('shuju',local.get("iSza"));
    if (local.get("iSza") == null) {
      local.set("iSza", 1);
    }
    if (local.get("iSza") == 1) {
      return;
    } else {
      this.time = setInterval(async () => {
        // console.log("数据", _that.getQueryVariable("orderNo"));
        // this.userId = this.$route.query.orderNo;
        this.userId = _that.$route.query.orderNo;
        // this.userId = _that.getQueryVariable("orderNo");
        // _that.deleteParams('code')
        // _that.deleteParams('code')
        // console.log("--", this.userId);
        // if (this.userId == undefined) {
        //   clearInterval(time);
        //   return;
        // }
        if (this.userId == false) {
          // if (this.userId) {
          clearInterval(this.time);
          return;
        }

        let a = {
          orderNo: this.userId,
        };
        local.set("iSza", 1);
        // const hat = _that;
        const { code, data } = await authBack(a);
        if (code == 0) {
          console.log("00----", data);
          this.$message({
            message: "身份验证成功",
            type: "success",
            offset: 100,
          });
          // window.location.open('http://192.168.100.116:8080/n/user/_auth')
          // console.log("数据", hat.deleteParams());
          clearInterval(this.time);
          return;
        } else {
          this.$message({
            message: "身份验证失败",
            type: "error",
            offset: 100,
          });
          // console.log("数据", hat.deleteParams());
          //  window.location.open('http://192.168.100.116:8080/n/user/_auth')
          clearInterval(this.time);
          return;
        }
        // clearInterval(this.time);
        // return;
        // _that.deleteParams('orderNo')
      }, 1000);
    }
    // this.time = setInterval(async () => {
    //   console.log("数据", _that.getQueryVariable("orderNo"));
    //   // this.userId = this.$route.query.orderNo;
    //   this.userId = _that.getQueryVariable("orderNo");
    //   // _that.deleteParams('code')
    //   // _that.deleteParams('code')
    //   // console.log("--", this.userId);
    //   // if (this.userId == undefined) {
    //   //   clearInterval(time);
    //   //   return;
    //   // }
    //   if (this.userId == false) {
    //     // if (this.userId) {
    //     clearInterval(this.time);
    //     return;
    //   }

    //   let a = {
    //     orderNo: this.userId,
    //   };
    //   local.SessionSet('iSza',1)
    //   // const hat = _that;
    //   const { code, data } = await authBack(a);
    //   if (code == 0) {
    //     console.log("00----", data);
    //     this.$message({
    //       message: "身份验证成功",
    //       type: "success",
    //       offset: 100,
    //     });
    //     // window.location.open('http://192.168.100.116:8080/n/user/_auth')
    //     // console.log("数据", hat.deleteParams());
    //     clearInterval(this.time);
    //     return;
    //   } else {
    //     this.$message({
    //       message: "身份验证失败",
    //       type: "error",
    //       offset: 100,
    //     });
    //     // console.log("数据", hat.deleteParams());
    //     //  window.location.open('http://192.168.100.116:8080/n/user/_auth')
    //     clearInterval(this.time);
    //     return;
    //   }
    //   // clearInterval(this.time);
    //   // return;
    //   // _that.deleteParams('orderNo')
    // }, 1000);
  },
  methods: {
    deleteParams() {
      // console.log("数据", (window.location.search = ""));
      // const before1 = a.substring(0,index);
      // var loca = window.location;
      // var baseUrl =
      //   loca.origin +
      //   loca.pathname +
      //   loca.hash.substring(0, loca.hash.indexOf("?") + 1);
      // var query = loca.hash.substring(loca.hash.indexOf("?") + 1);
      // if (query.indexOf(name) > -1) {
      //   var obj = {};
      //   var arr = query.split("&");
      //   for (var i = 0; i < arr.length; i++) {
      //     arr[i] = arr[i].split("=");
      //     obj[arr[i][0]] = arr[i][1];
      //   }
      //   delete obj[name];
      //   var url =
      //     baseUrl +
      //     JSON.stringify(obj)
      //       .replace(/[\"\{\}]/g, "")
      //       .replace(/\:/g, "=")
      //       .replace(/\,/g, "&");
      //   return url;
      // }
    },
    getQueryVariable(variable) {
      // var query = window.location.search;
      // query.substring(query.indexOf("orderNo") + 1)
      // console.log('数据123',query.substring(query.indexOf("orderNo") + 8)); //.indexOf("orderNo")
      // console.log('数据11',query.substring(query.indexOf("orderNo") + 8).substring(0,query.substring(query.indexOf("orderNo") + 8).indexOf('&')));
      // var vars = query.split("&");

      // const orderNo = query.substring(query.indexOf("orderNo") + 8).substring(0,query.substring(query.indexOf("orderNo") + 8).indexOf('&'));
      // if(query){
      //   return orderNo
      // }
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    async info() {
      let a = local.get("access_token");
      //获取实名状态
      const { code, data } = await infocur(a);
      if (code == 0) {
        // console.log("00", data);
        this.iSrealAuthStatus = data.user.realAuthStatus;
        this.iSfaceAuthStatus = data.user.faceAuthStatus;
        this.iSeduAuthStatus = data.user.eduAuthStatus;
        this.parent_id = data.user.id;
      }
    },
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    async authS() {
      if (/^[\u4e00-\u9fa5]{2,6}$/.test(this.name)) {
        // console.log("--");
        this.nameT = false;
      } else {
        // console.log("====");
        this.nameT = true;
        return;
      }
      if (/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(this.sfzh)) {
        // alert("身份证输入不合法");
        this.sfzhT = false;
      } else {
        this.sfzhT = true;
        return;
      }
      let card = {
        idcard: this.sfzh,
      };
      const D = await iSidcard(card);
      this.tkL();
      if (D.code == 0) {
        // console.log('00',D.data);
        if (D.data !== 0) {
          this.$message({
            message: "你的身份信息已在其他账号进行过认证,建议你用回原账号",
            type: "error",
            offset: 100,
          });
          // alert('1111111')
        } else {
          // let b= {
          //   Action:'IdCardVerification',
          //   Version:'2018-03-01',
          //   IdCard:this.sfzh,
          //   Name:this.name,
          // }

          // const t = await Action(b);
          // if(code == 0){
          // console.log('aa',t);
          // }

          let a = {
            idcard: this.sfzh,
            source: 0,
            userName: this.name,
          };
          const { code, data, msg } = await authSb(a);
          if (code == 0) {
            console.log("00", data);
            this.iSsm = true; // 弹框
            // local.set("iSza", 0);
            let a = {
              webankAppId: data.appId,
              version: data.version,
              nonce: data.nonce,
              orderNo: data.order,
              h5faceId: data.faceId,
              // url: "http://192.168.100.116:8081/#/n/user/_auth/#/",
              url:
                process.env.NODE_ENV === "production"
                  ? "http://www.yezai.love/n/user/_auth"
                  : "http://192.168.100.116:8082/n/user/_auth",

              // :  "http://localhost:8081/n/user/_auth",
              // url:`http://192.168.100.116:8081/${window.location.pathname}`,
              userId: data.userId,
              sign: data.sign,
            };
            this.url = `https://miniprogram-kyc.tencentcloudapi.com/api/pc/login?${Qs.stringify(
              a
            )}`;
          } else {
            this.$message({
              message: msg,
              type: "error",
              offset: 100,
            });
          }
        }
      }
    },
    jump() {
      this.iSsm = false;
      window.open(`${this.url}`, "_blank");
      // window.open(`${this.url}`, "_self");
      // local
      local.set("iSza", 0);
      //  this.tkL()
      // window.location.href = `${this.url}`;
    },
    destroyed() {
      clearInterval(this.time);
    },
  },
};
</script>

<style lang="less" scoped>
.auth-boxs {
  // width: 880px;
  // padding: 0px 26px;
  height: 720px;
  display: flex;
  flex-direction: column;
  .box1 {
    display: flex;
    // justify-content: space-between;
    .box1-1,
    .box1-2 {
      display: flex;
      align-items: center;
      width: 360px;
      height: 130px;
      // padding: 18px;
      padding: 20px 20px 40px 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(223, 223, 223, 0.5);
      border-radius: 10px;
      position: relative;
      margin: 0px 40px 30px 0px;
      .box1-right {
        display: flex;
        flex-direction: column;
        // width: 190px;
        p:nth-child(1) {
          font-size: 16px;
          color: #333333;
          line-height: 22px;
        }
        p:nth-child(2) {
          font-size: 14px;
          width: 214px;
          height: 40px;
          color: #adadad;
          line-height: 20px;
          margin-top: 8px;
        }
        .renzheng {
          // align-self: flex-end;
          position: absolute;
          right: 20px;
          bottom: 12px;
          width: 86px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 14px;
          border: 1px solid #ff686f;
          font-size: 14px;
          color: #ff686f;
          cursor: pointer;
        }
        .Yrenzheng {
          position: absolute;
          right: 20px;
          bottom: 12px;
          width: 86px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 14px;
          border: 1px solid #ccc;
          font-size: 14px;
          color: #999;
          cursor: pointer;
        }
      }
      img {
        width: 70px;
        height: 70px;
        margin-right: 20px;
      }
    }
  }
  .box2 {
    // margin-top: 36px;
    display: flex;
    align-items: center;
    width: 360px;
    height: 130px;
    // padding: 18px;
    padding: 20px 20px 40px 20px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(223, 223, 223, 0.5);
    border-radius: 10px;
    position: relative;
    .box1-right {
      display: flex;
      flex-direction: column;
      p:nth-child(1) {
        font-size: 16px;

        color: #333333;
        line-height: 22px;
      }
      p:nth-child(2) {
        font-size: 14px;
        width: 214px;
        height: 40px;
        color: #adadad;
        line-height: 20px;
        margin-top: 8px;
      }
      .renzheng {
        position: absolute;
        right: 20px;
        bottom: 12px;
        width: 86px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        border: 1px solid #ff686f;
        font-size: 14px;
        color: #ff686f;
        cursor: pointer;
      }
      .Yrenzheng {
        position: absolute;
        right: 20px;
        bottom: 12px;
        width: 86px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        border: 1px solid #ccc;
        font-size: 14px;
        color: #999;
        cursor: pointer;
      }
    }
    img {
      width: 70px;
      height: 70px;
      margin-right: 20px;
    }
  }
}
.auth-rz {
  display: flex;
  flex-direction: column;
  align-items: center;
  .auth-btn {
    width: 130px;
    height: 40px;
    background: #fd686e;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .srL {
    margin-top: 60px;
  }
  .sr {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 19px;
    position: relative;
    // margin: 20px;
    p {
      width: 150px;
      font-size: 16px;
      color: #666;
    }
    /deep/ .el-input__inner {
      height: 40px;
      width: 200px;
      background-color: #f5f5f5;
      border: none;
    }
    .srL-name {
      position: absolute;
      right: -50px;
      font-size: 12px;
      color: red;
    }
    .srL-sf {
      position: absolute;
      right: -65px;
      font-size: 12px;
      color: red;
    }
  }
  .auth-title {
    width: 360px;
    line-height: 20px;
    color: #444444;
    font-size: 14px;
  }
  .auth-img {
    width: 100px;
    height: 100px;
    // background-color: red;
    margin-bottom: 50px;
    margin-top: 24px;
  }
}
.sqfw {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  .card {
    position: fixed;
    top: 256px;
    left: 40%;
    // width: 380px;
    // height: 212px;
    width: 424px;
    height: 336px;
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    .title {
      // width: 440px;
      height: 44px;
      background: linear-gradient(314deg, #fd686e 0%, #ffa1a7 100%);
      // linear-gradient(270deg, #FFA1A7 0%, #FD686E 100%);
      font-size: 14px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      // img {
      //   width: 12px;
      //   height: 12px;
      //   position: absolute;
      //   right: 16px;
      //   cursor: pointer;
      // }
      i {
        color: #fff;
        position: absolute;
        right: 16px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .qr {
      // text-align: center;
      // font-size: 14px;
      // color: #333;
      // margin-top: 38px;
      padding: 26px 40px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 226px;
      img {
        width: 120px;
        height: 120px;
        //推荐二维码下载app软件
      }
      p {
        margin-top: 10px;
      }
      .btn {
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
        margin-top: 34px;
        width: 180px;
        height: 40px;
        background: #fd686e;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}
.sqfwL {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  .card {
    position: fixed;
    // top: 256px;
    top: 10%;
    left: 40%;
    // width: 380px;
    // height: 212px;
    width: 424px;
    // height: 336px;
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    .title {
      height: 44px;
      background-color: #f5f5f5;
      font-size: 14px;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      i {
        color: #adadad;
        position: absolute;
        right: 16px;
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
      }
    }
    .qr {
      padding: 30px 40px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // height: 226px;
      .qr-ti {
        // margin-top: 30px;
        margin-bottom: 21px;
      }
      .qr-img {
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
        div {
          margin: 10px 26px;
          img {
            width: 110px;
            height: 110px;
          }
          p {
            text-align: center;
            margin-top: 6px;
          }
        }
      }
      .btn {
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14px;
        width: 179px;
        height: 36px;
        background: #fd686e;
        border-radius: 20px;
      }
      .qr-title {
        font-size: 14px;
        color: #666666;
        margin-top: 16px;
      }
    }
  }
}
</style>